import InterpreteWordsView from "components/interprete/InterpreteWordsView";
import React from "react";
import { useRecoilValue } from "recoil";
import { interpreteActiveState } from "store/atoms/interprete_active";

const InterpreteWords = () => {
  const interpreteActive = useRecoilValue(interpreteActiveState);

  return <InterpreteWordsView interprete={interpreteActive} />;
};

export default InterpreteWords;
