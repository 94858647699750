import styled from "styled-components";
import { Work } from "type/work";

type WorkImageInfoProps = {
  work: Work;
};

const WorkImageInfo = ({ work }: WorkImageInfoProps) => {
  return (
    <>
      <Info>
        {work.author}, <i>{work.name}</i>
      </Info>

      <DivImg>
        <Img src={work.imagePath} />
      </DivImg>
    </>
  );
};

const DivImg = styled.div`
  max-height: 80%;
  width: 100%;
  margin-top: 1rem;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: flex-end;
  font-size: 1.5rem;
  justify-content: right;
  margin-top: 1rem;
  div {
    margin-left: 3rem;
  }
`;

export default WorkImageInfo;
