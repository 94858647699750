import InterpreteImagesView from "components/interprete/InterpreteImagesView";
import React from "react";
import { useRecoilValue } from "recoil";
import { interpreteActiveState } from "store/atoms/interprete_active";

const InterpreteImages = () => {
  const interpreteActive = useRecoilValue(interpreteActiveState);
  return <InterpreteImagesView interprete={interpreteActive} />;
};

export default InterpreteImages;
