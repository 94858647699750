import InterpreteSave from "components/interprete/InterpreteSave";
import DiktafontMainHeader from "components/layout/DiktafontMainHeader";
import NavActionEdit from "components/navigation/NavActionEdit";
import WorkImageInfo from "components/work/WorkImageInfo";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { imageDescriptionSearch } from "service/image/image";
import { interpreteAdd } from "service/interprete/interprete";
import { interpreteActiveState } from "store/atoms/interprete_active";
import { recognitionTextState } from "store/atoms/recognition_text";
import { workActiveState } from "store/atoms/work_active";
import styled from "styled-components";
import { ImageDescription, Interprete } from "type/interprete";
import { interpreteDefaultGet } from "util/interprete_common";

const WorkInterpreteInfo = () => {
  const [interpreteActive, setInterpreteActive] = useRecoilState(
    interpreteActiveState
  );
  const workActive = useRecoilValue(workActiveState);
  const [recognitionText, setRecognitionText] =
    useRecoilState(recognitionTextState);
  useEffect(() => {
    console.log("recognitionText", recognitionText);
    searchActivate(recognitionText);
    setRecognitionText("");
  }, [recognitionText]);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const saveActivate = async (
    interpreteName: string,
    interpreteAuthor: string
  ) => {
    setSaveOpen(false);
    const interprete: Interprete = JSON.parse(JSON.stringify(interpreteActive));
    if (workActive) {
      interprete.workId = workActive.id as string;
    }
    interprete.interpreteName = interpreteName;
    interprete.interpreteAuthor = interpreteAuthor;
    const interpreteId: string = await interpreteAdd(interprete);
    toast.success("Interpretation completed");
    console.log("interpretation added", interpreteId);

    setInterpreteActive(interpreteDefaultGet());

    navigate("/");
  };

  const interpretationCompleteHandle = async () => {
    setSaveOpen(true);
  };

  const searchActivate = async (searchText: string) => {
    const interprete: Interprete = JSON.parse(JSON.stringify(interpreteActive));
    console.log("interprete", interprete);

    const imageDescription: ImageDescription = await imageDescriptionSearch(
      searchText,
      "en"
    );
    interprete.interprete.push(imageDescription);
    setInterpreteActive(interprete);
    console.log("searchActivate", searchText);
  };

  const author: string = "Author";
  const work: string = "Work";

  return (
    <Div>
      <InterpreteSave
        saveOpen={saveOpen}
        setSaveOpen={setSaveOpen}
        saveActivate={saveActivate}
      />
      <DivNav>
        <DiktafontMainHeader diktafontNavigationActToggle={() => {}} />
        <DivCompleteNav onClick={interpretationCompleteHandle}>
          COMPLETE
        </DivCompleteNav>
      </DivNav>
      {workActive && <WorkImageInfo work={workActive} />}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  text-align: right;
}
`;

const DivCompleteNav = styled.div`
  cursor: pointer;
  color: #764141;
`;

const DivNav = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: right;
  align-items: center;
  font-size: 1.5rem;
`;

export default WorkInterpreteInfo;
