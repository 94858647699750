import Guide from "components/guide/Guide";
import InterpreteLangSelect from "components/lang/InterpreteLangSelect";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { interpreteLangState } from "store/atoms/interprete_lang";
import styled from "styled-components";

const TitleLanguage = () => {
  const [guideOpen, setGuideOpen] = useState<boolean>(false);
  const { control } = useForm();
  const [interpreteLang, setInterpreteLang] =
    useRecoilState(interpreteLangState);
  const navigate = useNavigate();

  const interpretationsNavigate = () => {
    navigate("/work-interpretations");
  };

  const langSelectHandle = (value: string) => {
    console.log("langSelectHandle", value);
    setInterpreteLang(value);
  };

  const guideNavClickHandle = () => {
    setGuideOpen(true);
  };

  return (
    <Div>
      <Title>NASOCIR</Title>
      <LanguageNav>
        <InterpreteLangSelect
          name="interpreteLang"
          label="Jezik opisa"
          defaultValue="sr"
          control={control}
          onSelect={langSelectHandle}
        />
      </LanguageNav>
      <DivGuideNav onClick={guideNavClickHandle}>UPUTSTVO</DivGuideNav>
      <Guide guideOpen={guideOpen} setGuideOpen={setGuideOpen} />
      <DivLicence>
        All paintings photos: Public domain, via Wikimedia Commons
      </DivLicence>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 20%;
  flex-direction: column;
  color: #764141;
`;

const Title = styled.p`
  font-size: 4rem;
`;

const LanguageNav = styled.div`
  font-size: 2rem;
  display: flex;
  flex-direction: column;
`;

const DivInterpretationsNav = styled.div`
  margin-top: 3rem;
  font-size: 2rem;
  cursor: pointer;
  color: #764141;
`;

const DivLicence = styled.div`
  position: fixed;
  bottom: 5rem;
  left: 5%;
  text-decoration: italic;
  color: black;
`;

const DivGuideNav = styled.div`
  margin-top: 3rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: black;
`;

export default TitleLanguage;
