import { Button } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { interpreteDelete } from "service/interprete/interprete";
import { workDelete } from "service/work/work_common";
import { workCollectionsState } from "store/atoms/work_collections";
import styled from "styled-components";
import { Interprete } from "type/interprete";
import { Work } from "type/work";
import { workCollectionNameGet } from "util/work_collection_common";

type Props = {
  index: number;
  interprete: Interprete;
  afterDelete?: Function;
};

const InterpreteItem = ({ index, interprete, afterDelete }: Props) => {
  const workCollections = useRecoilValue(workCollectionsState);
  // const [workItem, setWorkItem] = useState<Work>(work);
  const rowStart: number = Math.trunc(index / 5) + 1;
  const rowEnd: number = rowStart + 1;
  const handleDeleteClick = async () => {
    if (interprete.id) {
      await interpreteDelete(interprete.id);
      toast.success("Interpretation deleted");
      if (afterDelete) {
        afterDelete(interprete);
      }
    }
  };

  return (
    <Div
      style={{
        gridArea: `${rowStart} / ${(index % 5) + 1} / ${rowEnd} / ${
          (index % 5) + 2
        }`,
      }}
    >
      <div>
        <Link to={`/work-interprete/${interprete.id}`}>
          {interprete.interpreteAuthor} - {interprete.interpreteName}
        </Link>
      </div>
      {/* {workItem.imagePath && <Img src={workItem.imagePath} />} */}

      <DivButtons>
        <Button onClick={handleDeleteClick} variant="outlined">
          Delete
        </Button>
      </DivButtons>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  cursor: pointer;
  color: #764141;
  text-decoration: none;
`;

const DivButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

const Img = styled.img`
  width: 100%;
`;

export default InterpreteItem;
