import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { workGet } from "service/work/work_common";
import { Work } from "type/work";
import { Interprete } from "type/interprete";
import { interpreteGet } from "service/interprete/interprete";
import InterpreteImagesView from "components/interprete/InterpreteImagesView";
import WorkImageInfo from "components/work/WorkImageInfo";
import InterpreteWordsView from "components/interprete/InterpreteWordsView";

const WorkInterpreteView = () => {
  const { interpreteId } = useParams();
  const [interprete, setInterprete] = useState<Interprete | null>(null);
  const [work, setWork] = useState<Work | null>(null);
  useEffect(() => {
    if (interpreteId) {
      interpreteGet(interpreteId).then((item: Interprete) => {
        setInterprete(item);
      });
    }
  }, [interpreteId]);
  useEffect(() => {
    console.log("interprete", interprete);
    if (interprete) {
      workGet(interprete.workId).then((item: Work) => {
        setWork(item);
        console.log("work", item);
      });
    }
  }, [interprete]);

  console.log("interpreteId", interpreteId);

  return (
    <Div>
      {interprete && <InterpreteImagesView interprete={interprete} />}
      <WorkDiv>{work && <WorkImageInfo work={work} />}</WorkDiv>
      <WordsDiv>
        {interprete && <InterpreteWordsView interprete={interprete} />}
      </WordsDiv>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 90%;
  flex-direction: row;
  color: #764141;
`;

const WorkDiv = styled.div`
  margin-top: 2rem;
  margin-left: 5%;
  font-size: 1.5rem;
  width: 30%;
`;

const WordsDiv = styled.div`
  margin-top: 2rem;
  margin-left: 5%;
  font-size: 1.5rem;
  width: 10%;
`;

export default WorkInterpreteView;
