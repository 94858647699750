import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import RecognitionProcessing from "components/recognition/RecognitionProcessing";
import { initDB } from "react-indexed-db";
import { envTypeGet, apiUrlBaseGet } from "common/common";
import WorkList from "components/pages/WorkList/WorkList";
import WorkInterprete from "components/pages/WorkInterprete";
import WorkInterpretations from "components/pages/WorkInterpretations";
import NasocirStart from "components/pages/NasocirStart";
import Work from "components/pages/Work";
import WorkCollectionList from "components/pages/WorkCollectionList";
import { DBConfig } from "indexeddb/DBConfig";
import { useRecoilState } from "recoil";
import { workCollectionsState } from "store/atoms/work_collections";
import { workCollectionsGet } from "service/work_collection/work_collection_common";
import { WorkCollection } from "type/work_collection";
import { workCollectionActiveState } from "store/atoms/work_collection_active";
import InterpreteList from "components/pages/InterpreteList";
import WorkInterpreteView from "components/pages/WorkInterpreteView";

initDB(DBConfig);

console.log(
  "envTypeGet: ",
  envTypeGet(),
  "apiUrlBaseGet: ",
  apiUrlBaseGet(false)
);

export default function NasocirMainContent() {
  const [workCollectionActive, setWorkCollectionActive] = useRecoilState(
    workCollectionActiveState
  );
  const [workCollections, setWorkCollections] =
    useRecoilState(workCollectionsState);
  useEffect(() => {
    workCollectionsGet().then((workCollectionList: WorkCollection[]) => {
      console.log("workCollectionList", workCollectionList);
      setWorkCollections(workCollectionList);
    });
    if (workCollectionActive.id === null) {
      const defaultName = "simbolizam";
      workCollectionsGet({ name: defaultName }).then(
        (workCollectionList: WorkCollection[]) => {
          if (workCollectionList.length >= 1) {
            setWorkCollectionActive(workCollectionList[0]);
          }
        }
      );
    }
  }, []);

  const [diktafontNavigationAct, setDiktafontNavigationAct] = useState(false);
  const diktafontNavigationActToggle = () => {
    setDiktafontNavigationAct((currentValue) => !currentValue);
  };
  return (
    <Router>
      <div className="nasocir-main">
        <RecognitionProcessing />
        <Toaster />
        <Routes>
          <Route path="/" element={<NasocirStart />} />
          <Route path="/works" element={<WorkList />} />
          <Route path="/work/new" element={<Work />} />
          <Route path="/work/:workId" element={<Work />} />
          <Route path="/work-interprete/new" element={<WorkInterprete />} />
          <Route
            path="/work-interprete/:interpreteId"
            element={<WorkInterpreteView />}
          />
          <Route
            path="/work-interpretations"
            element={<WorkInterpretations />}
          />
          <Route path="/interpretations" element={<InterpreteList />} />
          <Route path="/work-collections" element={<WorkCollectionList />} />
        </Routes>
      </div>
    </Router>
  );
}
