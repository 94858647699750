import axios from "axios";
import { apiUrlGet } from "common/common";
import { Interprete } from "type/interprete";
import { interpreteDefaultGet } from "util/interprete_common";

export function keydownActionGet(ev: KeyboardEvent): string {
  // console.log('handleKeydown');
  let action: string = "";
  const key = ev.key;
  if (key == "Escape") {
    action = "escape";
  } else if (key == "F2") {
    action = "search";
  } else if (key == "F4") {
    action = "imLiClear";
  }
  return action;
}

export async function interpreteAdd(interprete: Interprete): Promise<string> {
  let interpreteId: string = "";
  const interpreteUrl = apiUrlGet("interprete");
  const response = await axios.post(interpreteUrl, {
    interpreteName: interprete.interpreteName,
    interpreteAuthor: interprete.interpreteAuthor,
    workId: interprete.workId,
    interprete: interprete.interprete,
  });
  interpreteId = response.data;

  return interpreteId;
}

export async function interpretationsGet(
  options: Object = {}
): Promise<Interprete[]> {
  let queryPart = "";
  if ("workCollection" in options) {
    queryPart +=
      (queryPart !== "" ? "&" : "") +
      "workCollection=" +
      options.workCollection;
  }
  if (queryPart) {
    queryPart = "?" + queryPart;
  }

  let interpretations: Interprete[] = [];
  const interpreteUrl = apiUrlGet("interprete" + queryPart);
  const response = await axios.get(interpreteUrl);
  // console.log("worksGet - workUrl", workUrl);
  console.log("interpretationsGet - response.data", response.data);

  interpretations = response.data.map((interpreteItem: Interprete) => {
    const interprete: Interprete = interpreteDefaultGet();
    interprete.id = interpreteItem.id;
    interprete.interpreteName = interpreteItem.interpreteName;
    interprete.interpreteAuthor = interpreteItem.interpreteAuthor;
    interprete.createdAt = interpreteItem.createdAt;
    interprete.interprete = interpreteItem.interprete;

    return interprete;
  });

  return interpretations;
}

export async function interpreteGet(interpreteId: string): Promise<Interprete> {
  let interprete: Interprete = interpreteDefaultGet();
  const interpreteUrl = apiUrlGet(`interprete/${interpreteId}`);
  const response = await axios.get(interpreteUrl);
  const interpreteItem = response.data;
  interprete.id = interpreteItem.id;
  interprete.interpreteName = interpreteItem.interpreteName;
  interprete.interpreteAuthor = interpreteItem.interpreteAuthor;
  interprete.createdAt = interpreteItem.createdAt;
  interprete.interprete = interpreteItem.interprete;
  interprete.workId = interpreteItem.work;

  return interprete;
}

export async function interpreteDelete(id: string): Promise<string> {
  const interpreteUrl = apiUrlGet(`interprete/${id}`);
  await axios.delete(interpreteUrl);

  return id;
}
