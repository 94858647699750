import React from "react";
import styled from "styled-components";
import { ImageDescription, Interprete } from "type/interprete";

type InterpreteWordsViewProps = {
  interprete: Interprete;
};

const InterpreteWordsView = ({ interprete }: InterpreteWordsViewProps) => {
  const interpreteWords = interprete.interprete.map(
    (imageDescription: ImageDescription, index: number) => (
      <InterpreteWord key={`word_${index}`}>
        {imageDescription.text}
      </InterpreteWord>
    )
  );

  return <Div>{interpreteWords.reverse()}</Div>;
};

const Div = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
`;

const InterpreteWord = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  text-align: right;
`;

export default InterpreteWordsView;
